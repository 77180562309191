import React from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { BasicLayout } from "./layouts/BasicLayout";
import { Taplink } from "./components/Taplink/Taplink";

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <BasicLayout />,
      children: [
        {
          path: ":uuid",
          element: <Taplink />
        }
      ],
    },
    {
      path: "*",
      element: <BasicLayout />,
    }
  ]);

  return <RouterProvider router={router} />;
}

export default App;
