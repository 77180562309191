import "./LinkIcon.css";
import vk from "../../../../assets/icons/vk.png";
import telega from "../../../../assets/icons/telegram.png";
import insta from "../../../../assets/icons/insta.png";
import linked from "../../../../assets/icons/link.png";
import { LinkIconProps } from "../types/image.type";

export const LinkIcon = ({ link }: LinkIconProps) => {
  const setIcon = () => {
    if (link.includes("vk.com")) return vk;
    if (link.includes("t.me")) return telega;
    if (link.includes("instagram")) return insta;
    return linked;
  };

  return (
    <div className="link-icon">
      <img src={setIcon()} alt="icon" />
    </div>
  );
};
