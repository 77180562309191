import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { ITaplinks } from "../../@types/bot.type";

export const botApi = createApi({
  reducerPath: "bot/api",
  baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_API }),
  endpoints: (builder) => ({
    getLink: builder.query<ITaplinks, string | undefined>({
      query: (uuid) => `link/${uuid}`,
    }),
  }),
});

export const { useGetLinkQuery } = botApi;
