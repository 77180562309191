import { Link, useParams } from "react-router-dom";
import "./Taplink.css";
import { TaplinkWrap } from "../UI/Wrappers/TaplinkWrap/TaplinkWrap";
import { Avatar } from "../UI/Images/Avatar/Avatar";
import { TaplinkName } from "../UI/Text/TaplinkName/TaplinkName";
import { LinkIcon } from "../UI/Images/LinkIcon/LinkIcon";
import { Text } from "../UI/Text/Text/Text";
import { useGetLinkQuery } from "../../services/store/bot/bot.api";
import { useEffect } from "react";

export const Taplink = () => {
  const { uuid } = useParams();
  const { data, isLoading } = useGetLinkQuery(uuid);

  useEffect(() => {
    if (data && data.links.length === 1)
      window.location.href = data.links[0].url;
  }, [data]);

  return (
    <>
      {isLoading && <Text text="Загрузка" />}
      {data && (
        <TaplinkWrap>
          <Avatar />
          <TaplinkName text={data.user.name} />
          <>
            {data.links.length > 1 &&
              data.links.map((item) => (
                <Link key={item.uuid} className="taplink-link" to={item.url}>
                  <LinkIcon link={item.url} />
                  <Text text={item.name.slice(5)} type="link-text" />
                </Link>
              ))}
          </>
        </TaplinkWrap>
      )}
    </>
  );
};
