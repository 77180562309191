import "./Avatar.css";
import nophoto from "../../../../assets/images/nophoto.png";

export const Avatar = () => {
  return (
    <div
      className="avatar"
      style={{ backgroundImage: `url(${nophoto})` }}
    ></div>
  );
};
