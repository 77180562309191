import { TextProps } from "../types/text.type";
import "./TaplinkName.css";

export const TaplinkName = ({ text }: TextProps) => {
  return (
    <div className="taplink-name">
      <h2>{text}</h2>
    </div>
  );
};
